.nav-main .navbar-brand{
  padding: 0;
}

.font-logo{
  /* font-family: "Noto Serif", serif; */
  font-family: "Cinzel Decorative", serif;
  font-weight: 300;
  margin-left: 8px;
}

.nav-main .navbar-brand img{
  max-height: 55px;
  margin: 8px 0;
}

.navbar.nav-main {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.navbar.nav-main *:not(input){
  /* color: #ffffff!important; */
}

.navbar.nav-main .navbar-brand img {
  max-width: 100px;
}
.navbar.nav-main .navbar-nav .nav-link {
  /* font-size: 12px; */
}
@media screen and (min-width: 1024px) {
  .navbar.nav-main {
    letter-spacing: 0.1em;
  }
  .navbar.nav-main .navbar-nav .nav-link {
    padding: 0.5em 1em;
  }
  .nav-main .search-and-icons {
    width: 50%;
  }
  .nav-main .search-and-icons form {
    flex: 0.5;
  }
}
@media screen and (min-width: 768px) {
  .nav-main .navbar-brand{
    padding: 5px 0;
  }  
  .navbar.nav-main .navbar-brand img {
    max-width: 7em;
    /* max-height: 75px; */
    margin: 0;
  }
  .navbar.nav-main .navbar-collapse {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .nav-main .search-and-icons {
    display: flex;
    align-items: center;
  }
  .navbar.nav-main .bottom-nav .nav-link {
    font-size: 12px;
    padding: 14px 0 6px 24px;
  }
  .navbar.nav-main .bottom-nav1 .nav-link {
    font-size: 14px;
    padding: 0px 0 0px 42px;
  }
  #search-navs{
    display: none!important;
  }
}

.nav-main .search-and-icons form input, .nav-search form input{
    color: #000000!important;
}

.nav-main .search-and-icons form inputs {
  border-radius: 0;
  height: 2em;
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='grey' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E")
    no-repeat 95%;
}
.nav-main .search-and-icons form input:focus {
  /* background: #fff; */
  box-shadow: none;
}
.nav-main .search-and-icons .user-icons div {
  padding-right: 1em;
}
.nav-main .contact-info p,
.nav-main .contact-info a {
  font-size: 0.9em;
  padding-right: 1em;
  color: grey;
}
.nav-main .contact-info a {
  padding-right: 0;
}
  