.bg-pink{
    background-color: #ed7f90;
}

.admin-navs .a-navlink{
    text-decoration: none;
}

.admin-canvas-detail-items {
    width: 40vw!important;
}

@media only screen and (max-width: 500px) {
    .offcanvas{
        width: 100vw!important;
    }
}